.objectives {
  display: flex;

  margin-bottom: 40px;

  flex-direction: column;
}

.miniatureAndColor__header,
.objectives__header,
.speakers__header {
  display: flex;

  margin-bottom: 16px;

  justify-content: space-between;
  align-items: center;
}

.objectives__left {
  display: flex;

  flex-direction: column;
  gap: 4px;
}

.miniatureAndColor__title,
.objectives__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #71798f;
}

.objectives__link {
  display: flex;

  align-items: center;
  gap: 4px;

  font-size: 14px;
  line-height: 16px;
  color: #5770f3;
}

.objectives__list {
  display: flex;

  flex-direction: column;
  gap: 8px;
}

.objective {
  display: flex;

  flex: auto;

  align-items: center;
  gap: 8px;
}

.buttonDelete {
  display: flex;

  width: 56px;
  height: 48px;

  justify-content: center;
  align-items: center;

  background-color: #eef0ff !important;
  border-radius: 12px;
}
