.container {
  display: flex;

  flex-direction: column;
}

.header {
  display: flex;

  margin-bottom: 16px;

  height: 56px;

  justify-content: space-between;
  align-items: center;
}

.title {
  display: flex;

  align-items: center;

  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #20233a;
}

.form {
  display: flex;

  flex-direction: column;
}

.label {
  margin-bottom: 8px;

  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #71798f;

  text-transform: uppercase;
}

.condition {
  display: flex;

  margin-bottom: 16px;

  flex-direction: column;
}

.pairs {
  display: flex;

  margin-bottom: 36px;

  flex-direction: column;
}

.pairs__list {
  display: flex;

  margin-bottom: 8px;

  flex: 1;
  width: 100%;

  flex-direction: column;
  gap: 8px;
}

.buttons {
  display: flex;

  align-self: flex-end;
  gap: 8px;
}
