.container {
  margin-bottom: 24px;
}

.statuses {
  display: flex;

  gap: 16px;
  flex-wrap: wrap;
}

.status {
  display: flex;

  gap: 8px;
}

.status__content {
  display: flex;

  flex-direction: column;
  gap: 4px;
}

.status__header {
  max-width: 168px;

  padding-top: 2px;

  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #515381;
}

.status__text {
  max-width: 168px;

  font-size: 14px;
  line-height: 16px;
  color: #A1AABC;
}
