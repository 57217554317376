/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
.input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  z-index: 1;

  top: 16px;
  right: 15px;

  height: 24px;
  width: 24px;

  background: transparent;
  color: transparent;
}

/* adjust increase/decrease button */
.input[type='date']::-webkit-inner-spin-button {
  z-index: 1;
}

/* adjust clear button */
.input[type='date']::-webkit-clear-button {
  z-index: 1;
}
