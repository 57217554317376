.container {
  display: flex;

  flex-direction: column;

  border: 1px solid #E9ECF0;
  border-radius: 20px;

  overflow: hidden;
}

.header {
  display: flex;

  /* height: 64px; */
  padding: 8px 16px;

  align-items: center;

  background-color: #EEF0FF;
}

.header__fields {
  display: flex;

  margin-right: 8px;

  align-items: center;
}

.header__button {
  display: flex;

  height: 40px;
  width: 40px;

  justify-content: center;
  align-items: center;

  background-color: #5770F3 !important;
  border-radius: 24px;
}

.status {
  display: flex;

  margin-left: auto;

  align-items: center;
  gap: 8px;
}

.status span {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  color: #515381;
}

.examples {  
  padding: 8px;

  background-color: #FFFFFF;
}

.examples__list {
  display: flex;
  
  gap: 4px;
}

.examples__item {
  display: flex;

  flex: 1;

  padding: 12px;

  flex-direction: column;
  gap: 8px;

  background-color: #F5F5F5;
  border-radius: 12px;
}

.examples__empty {
  font-size: 16px;
  line-height: 19px;
  color: #A1AABC;
}
