.pagination {
  display: flex;

  padding: 10px;

  gap: 12px;
}

.pagination__page,
.pagination__button {
  display: flex;

  height: 48px;
  width: 48px;

  justify-content: center;
  align-items: center;

  border: 1px solid #eff1f4;
  border-radius: 12px;

  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;

  cursor: pointer;
  user-select: none;
  transition: 0.3s all ease;
}

.pagination__button:hover,
.pagination__page:hover {
  border: 1px solid #8fa0f4;
}

.pagination__page {
  color: #71798f;
}

.pagination__page_active {
  background-color: #5770f3;
  border: none;

  color: #ffffff;
}

.pagination__page_active:hover {
  border: none;
  cursor: auto;
}

.pagination__button {
  color: #5770f3;
}

.pagination__button:disabled {
  color: #a1aabc;

  /* opacity: .3; */
  cursor: auto;
}
