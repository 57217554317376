.sidebar {
  display: flex;

  width: 210px;

  flex-direction: column;
  align-items: flex-start;
}

.sidebar__back {
  display: flex;

  margin-bottom: 30px;

  align-items: center;
  gap: 4px;

  user-select: none;
}

.sidebar__back p {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #71798f;
}

.sidebar__image {
  height: 160px;
  width: 160px;

  margin-bottom: 24px;

  border-radius: 16px;
}

.sidebar__image img {
  height: 100%;
  width: 100%;
}

.sidebar__title {
  margin-bottom: 24px;

  max-width: 100%;
  max-height: 96px;

  font-weight: 700;
  font-size: 21px;
  line-height: 24px;
  color: #20233a;

  word-wrap: break-word;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sidebar__options {
  display: flex;

  flex-direction: column;
  gap: 5px;
}

.sidebar__option {
  display: flex;

  height: 44px;
  width: 202px;

  padding: 14px 0 11px 21px;

  border-radius: 34px;

  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #20233a;
  text-align: start;

  transition: all 0.3s ease;
}

.sidebar__option_active {
  background-color: #eef0ff;
}
