.content {
  display: flex;

  width: calc(100% - 264px);

  flex-direction: column;
}

.header {
  display: flex;
  position: relative;

  height: 49px;

  align-items: center;
}

.title {
  max-width: 400px;
  margin-right: 14px;

  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #20233a;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.buttonAudio {
  display: flex;

  margin-right: auto;

  width: 30px;
  height: 30px;

  justify-content: center;
  align-items: center;

  border-radius: 100%;
  background: #5770f3;
}

.id {
  position: absolute;

  top: -35px;
  left: 0;

  font-size: 16px;
  line-height: 19px;
  color: #71798f;
}

.controls {
  display: flex;

  align-items: center;
  gap: 32px;
}

.buttonEdit,
.buttonDelete {
  display: flex;

  height: 24px;
  width: 24px;

  justify-content: center;
  align-items: center;
}

.transliteration {
  margin-bottom: 24px;

  font-size: 18px;
  line-height: 21px;
  color: #a1aabc;
}

.translation {
  display: flex;

  margin-bottom: 16px;

  flex-direction: column;
}

.translation label {
  margin-bottom: 8px;

  font-size: 14px;
  line-height: 16px;
  color: #71798f;
}

.translation span {
  font-weight: 700;
  font-size: 21px;
  line-height: 24px;
  color: #20233a;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.examples {
  display: flex;

  flex-direction: column;
}

.examples__header {
  display: flex;

  margin-bottom: 24px;

  justify-content: space-between;
  align-items: center;
}

.examples__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #20233a;
}

.examples__list {
  display: flex;

  margin-bottom: 24px;

  flex-direction: column;
  gap: 24px;
}

.references {
  display: flex;
  flex-direction: column;
  gap: 16px;

  margin-bottom: 24px;
}

.references__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #20233a;
}
