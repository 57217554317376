.container {
  display: flex;

  padding: 8px 0 7px;

  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #eff1f4;
}

.content {
  display: flex;

  max-width: 500px;

  flex-direction: column;
}

.original {
  margin-bottom: 4px;

  font-size: 16px;
  line-height: 19px;
  color: #25334f;

  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
}

.translation {
  font-size: 14px;
  line-height: 16px;
  color: #71798f;

  white-space: normal;
  text-overflow: unset;
  overflow: unset;
  word-wrap: break-word;
}

.controls {
  display: flex;
}

.buttonEdit,
.buttonDelete {
  display: flex;

  height: 24px;
  width: 24px;

  justify-content: center;
  align-items: center;
}

.buttonEdit {
  margin-right: 32px;
}
