.container {
  display: flex;

  min-height: 100vh;

  overflow-x: auto;
}

.content {
  margin-left: 271px;

  width: calc(100% - 271px);

  padding: 67px 47px 64px 87px;

  transition: all 0.3s ease;
}

.content_collapsed {
  margin-left: 90px;

  width: calc(100% - 90px);
}

@media screen and (max-width: 1512px) {
  .content {
    min-width: calc(var(--width-content) - 271px);
  }

  .content_collapsed {
    min-width: calc(var(--width-content) - 90px);
  }
}
