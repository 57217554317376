.form {
  display: flex;

  flex-direction: column;
}

.text {
  margin-bottom: 30px;

  padding: 16px 24px;

  background-color: #eef0ff;
  border-radius: 16px;
}

.buttons {
  display: flex;

  justify-content: flex-end;
  gap: 8px;
}
