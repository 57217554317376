.container {
  display: flex;
  position: relative;

  height: 62px;
  
  padding: 8px 0px 12px;

  align-items: center;
  gap: 16px;

  background-color: #eef0ff;
  border-bottom: 2px solid rgba(113, 121, 143, 0.1);
}

.container:hover .controls {
  opacity: 1;
}

.dragHandle {
  display: flex;

  height: 24px;
  height: 24px;

  justify-content: center;
  align-items: center;

  cursor: grab;
}

.text {
  display: flex;

  max-width: 100%;
  flex: 1;

  flex-direction: column;
  gap: 4px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.heading {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #71798f;

  text-transform: uppercase;
}

.title {
  flex: 1;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #20233a;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.controls {
  display: flex;
  position: absolute;

  height: 46px;

  padding: 0 32px;
  right: 28px;

  align-items: center;
  gap: 32px;

  background-color: #eef0ff;

  opacity: 0;
  transition: all 0.3s ease;
}

.stepsAmount {
  width: 39px;

  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #71798f;
}
