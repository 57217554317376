.container {
  display: flex;

  width: 100%;
  height: 88px;

  padding: 16px;

  align-items: center;
  gap: 20px;

  background-color: #f5f5f5 !important;
  border: 2px solid #f5f5f5 !important;
  border-radius: 20px;

  transition: all 0.3s ease;
  user-select: none;
}

.container_selected {
  background-color: #eef0ff !important;
  border-color: #5770f3 !important;
  border-radius: 20px;
}

.image {
  display: flex;

  height: 54px;
  width: 54px;
}

.image img {
  height: 100%;
  width: 100%;
}

.text {
  display: flex;

  max-width: calc(100% - 74px);
  flex: 1;

  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.order {
  font-size: 14px;
  line-height: 16px;
  color: #71798f;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.title {
  max-width: 100%;
  flex: 1;

  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #1c2438;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
