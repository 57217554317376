.root {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .header {
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  
    margin-bottom: 24px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    flex: 1;
  
    gap: 24px;
  }
  
  .textareaContainer {
    height: 100%;
  }
  
  .textarea {
    height: 100%;
    padding: 30px 24px;
  
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    background: #EEF0FF;
  }
  
  .buttons {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
  }
  
  .button {
    width: 228px;
  }
  