.container {
  display: flex;
  position: relative;

  width: 100%;

  flex-direction: column;
}

.container_disabled {
  opacity: 0.3;
}

.pull-tab {
  position: absolute;
  z-index: 2;

  bottom: 12px;
  right: 12px;

  height: 9px;
  width: 9px;

  background-image: url('assets/images/svg/resize.svg');

  pointer-events: none;
}

.pull-tab_error {
  bottom: 32px;
}

.textarea {
  position: relative;
  z-index: 1;

  min-height: 104px;
  width: 100%;

  padding: 12px;

  transition: all 0.3s ease;
}

.textarea::-webkit-resizer {
  display: none;
}

.textarea_dark {
  background-color: #eff1f4;
  border-radius: 14px;

  font-size: 16px;
  line-height: 19px;
  color: #20233a;
}

.textarea_dark:focus,
.textarea_dark:focus-visible {
  background-color: #eef0ff;

  outline: none;
}

.textarea_bordered {
  background-color: #ffffff;
  border: 2px solid #e9ecf0;
  border-radius: 14px;

  font-size: 16px;
  line-height: 19px;
  color: #20233a;
}

.textarea_bordered:focus,
.textarea_bordered:focus-visible {
  border-color: #5770f3;

  outline: none;
}

.textarea_error {
  margin-bottom: 6px;

  background-color: rgba(255, 68, 68, 0.1);
}

.errorMessage {
  display: block;
  margin-left: 16px;

  font-size: 12px;
  line-height: 14px;

  color: #ff4444;
}
