.container {
  display: flex;

  width: 404px;

  flex-direction: column;
  gap: 26px;
}

.header {
  display: flex;

  justify-content: space-between;
  align-items: center;
}

.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;

  color: #20233a;
}

.buttonClose {
  height: 24px;
  width: 24px;
}

.form {
  display: flex;

  flex-direction: column;
  gap: 24px;
}

.fields {
  display: flex;

  flex-direction: column;
  gap: 8px;
}

.password {
  position: relative;
}

.buttonRefresh {
  display: flex;
  position: absolute;

  top: 7px;
  right: 8px;

  height: 48px;
  width: 64px;

  justify-content: center;
  align-items: center;

  background: #5770f3 !important;
  border-radius: 12px;
}

.role {
  display: flex;

  flex-direction: column;
}

.errorMessage {
  display: block;

  margin-top: 6px;
  margin-left: 16px;

  font-size: 12px;
  line-height: 14px;

  color: #ff4444;
}

.multiSelect {
  width: 198px;
  height: 56px;

  border: 2px solid rgba(140, 154, 175, 0.25);
  border-radius: 14px;
}

.buttons {
  display: flex;

  gap: 8px;
}
