.container {
  display: flex;

  width: 210px;

  flex-direction: column;
  align-items: flex-start;
}

.sidebar__back {
  display: flex;

  height: 28px;
  margin-bottom: 35px;

  align-items: center;
  gap: 4px;

  user-select: none;
}

.sidebar__back p {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #71798f;
}

.sidebar__info {
  display: flex;

  flex-direction: column;
}

.module {
  display: flex;

  margin-bottom: 20px;

  flex-direction: column;
}

.module__order {
  margin-bottom: 8px;

  font-weight: 700;
  font-size: 21px;
  line-height: 24px;
  color: #20233a;
}

.module__name {
  font-weight: 700;
  font-size: 21px;
  line-height: 24px;
  color: #20233a;
}

.lesson {
  display: flex;

  flex-direction: column;
}

.lesson__image {
  margin-bottom: 20px;

  height: 54px;
  width: 54px;
}

.lesson__image img {
  height: 100%;
  width: 100%;
}

.lesson__order {
  margin-bottom: 4px;

  font-size: 14px;
  line-height: 16px;
  color: #71798f;
}

.lesson__name {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #20233a;
}
