.custom-select__control {
  /* border: 2px solid rgba(140, 154, 175, 0.25) !important; */
  border-radius: 14px !important;

  overflow: 'hidden' !important;
}

/* .custom-select__placeholder {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #808080 !important;
} */

.custom-select__indicator-separator {
  display: none !important;
}

.custom-select__clear-indicator {
  display: none !important;
}

.custom-select__menu {
  padding: 0 !important;
  margin: 0 !important;

  background-color: #ffffff !important;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.11) !important;
  border-radius: 12px !important;

  overflow: hidden !important;
}
