.buttonRefresh {
  display: flex;
  position: absolute;

  top: 7px;
  right: 8px;

  height: 48px;
  width: 64px;

  justify-content: center;
  align-items: center;

  background: #5770f3 !important;
  border-radius: 12px;
}

.errorMessage {
  display: block;

  margin-top: 6px;
  margin-left: 16px;

  font-size: 12px;
  line-height: 14px;

  color: #ff4444;
}

.multiSelect {
  width: 198px;
  height: 56px;

  border: 2px solid rgba(140, 154, 175, 0.25);
  border-radius: 14px;
}

.image {
  position: relative;

  margin-bottom: 24px;

  height: 272px;
  width: 272px;

  background: #eff1f4;

  user-select: none;
  overflow: hidden;
  cursor: pointer;
}

.image img {
  height: 100%;
  width: 100%;
}

.uploadIcon {
  position: absolute;
  display: flex;

  right: 12px;
  bottom: 12px;

  height: 40px;
  width: 40px;

  justify-content: center;
  align-items: center;

  border-radius: 6px;
  background: rgba(0, 0, 0, 0.2);

  color: #ffffff;
}
