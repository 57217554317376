.steps {
  display: flex;
  position: relative;

  margin-bottom: 16px;

  max-width: 100%;

  flex-direction: column;

  user-select: none;
}

.steps__list {
  display: flex;

  max-width: 100%;

  padding-bottom: 16px;

  overflow-x: auto;
}
