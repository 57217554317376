.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.subtitle {
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #5A667B;
}

.title {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: -0.32px;
  color: #20233A;
}
