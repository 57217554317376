.container {
  display: flex;

  height: 64px;
  flex: 1;

  padding: 20px 16px;

  align-items: center;

  background: #eef0ff;
  border-radius: 12px;
}

.iconAudio,
.buttonPlay {
  display: flex;

  height: 24px;
  width: 24px;

  justify-content: center;
  align-items: center;
}

.iconAudio {
  margin-right: 32px;
}

.buttonPlay {
  margin-right: 16px;
}

.audioPlayer {
  display: flex;

  flex: 1;
  align-items: center;
}

.inputCont {
  display: flex;
  position: relative;

  height: 20px;
  width: 100%;

  flex-direction: column;
  justify-content: center;

  overflow-x: hidden;
}

.audioRoad {
  height: 2px;
  width: 100%;

  background: rgba(87, 112, 243, 0.3);
  border-radius: 10px;

  -webkit-appearance: none !important;
  appearance: none !important;
}

.audioRoad::-webkit-slider-thumb {
  height: 20px;
  width: 20px;

  background: #5770f3;
  border-radius: 100%;

  -webkit-appearance: none !important;
}

.progressBar {
  position: absolute;

  height: 2px;
  width: 100%;

  background-color: #4d62ff;
  border-radius: 10px;

  transform: translateX(-100%);
}
