.container {
  display: flex;

  width: 210px;

  flex-direction: column;
  align-items: flex-start;
}

.back {
  display: flex;

  height: 28px;
  margin-bottom: 35px;

  align-items: center;
  gap: 4px;

  user-select: none;
}

.image {
  margin-bottom: 20px;

  height: 54px;
  width: 54px;
}

.image img {
  height: 100%;
  width: 100%;
}
