.label {
  margin-bottom: 8px;

  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #71798f;

  text-transform: uppercase;
}

.top_big {
  flex-direction: column;

  gap: 16px;
}

.upload {
  display: flex;

  flex-direction: column;

  transition: all 0.3s ease;
}

.upload__label {
  display: block;

  background: #eef0ff;
  border: 1px dashed #5770f3;
  border-radius: 12px;

  font-size: 16px;
  line-height: 19px;
  color: #20233a;

  cursor: pointer;
}

.upload__label_error {
  margin-bottom: 6px;

  background-color: rgba(255, 68, 68, 0.1);
}

.input {
  display: none;
}
