.extraWords {
  display: flex;

  margin-bottom: 16px;

  flex-direction: column;
  gap: 8px;
}

.label {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #71798f;

  text-transform: uppercase;
}

.extraWords__description {
  font-size: 14px;
  line-height: 16px;

  color: #71798f;
}
