.codex-editor__redactor {
  padding-bottom: 0 !important;
}

.ce-block__content {
  display: flex;

  margin: 0 !important;
  max-width: none !important;

  justify-content: flex-start;
}

.ce-toolbar__content {
  /* margin-left: -20px !important;
  margin-right: -20px !important; */

  max-width: none !important;
}

.cdx-block {
  width: 100%;
}

.ce-header {
  overflow: hidden;
}

h1.ce-header {
  font-size: 30px;
}

h2.ce-header {
  font-size: 24px;
}

h3.ce-header {
  font-size: 20px;
}

h4.ce-header {
  font-size: 18px;
}

/* SimpleAudio block */

.simple-audio {
  width: 100%;

  padding: 0.4em 0;
}

.simple-audio label {
  display: flex;

  justify-content: center;
  align-items: center;
}

.simple-audio label div {
  display: flex;

  margin-right: 6px;

  height: 24px;
  width: 24px;

  justify-content: center;
  align-items: center;
}

.simple-audio {
  display: flex;

  flex-direction: column;
  gap: 8px;
}

.generate-container {
  display: flex;

  align-items: center;
  gap: 8px;
}

.generate-container label {
  height: 46px;
  flex-shrink: 0;
}

.audio-wrapper {
  max-width: 100%;
  margin-bottom: 15px;
}

.audio-wrapper audio {
  width: 100%;
}
