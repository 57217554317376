.container {
  display: flex;

  max-width: 100%;

  flex-direction: column;
}

.header {
  display: flex;
  position: relative;

  height: 76px;
  max-width: 100%;

  padding: 11px 24px 11px 16px;

  align-items: center;
  gap: 14px;

  background-color: #eef0ff;
  border-radius: 12px;
}

.header:hover .controls {
  opacity: 1;
}

.dragHandle {
  display: flex;

  height: 24px;
  height: 24px;

  justify-content: center;
  align-items: center;

  cursor: grab;
}

.moveButtons {
  display: flex;

  /* flex-direction: column; */
  gap: 5px;
}

.buttonUp,
.buttonDown {
  display: flex;

  height: 24px;
  width: 24px;

  justify-content: center;
  align-items: center;
}

.image {
  height: 54px;
  width: 54px;
}

.image img {
  height: 100%;
  width: 100%;
}

.text {
  display: flex;

  max-width: 100%;
  flex: 1;

  flex-direction: column;
  gap: 4px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.heading {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #71798f;
}

.title {
  flex: 1;

  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #1c2438;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.controls {
  display: flex;
  position: absolute;

  height: 54px;

  padding: 0 32px;
  right: 48px;

  align-items: center;
  gap: 32px;

  background-color: #eef0ff;

  opacity: 0;
  transition: all 0.3s ease;
}

.addSectionsToLesson {
  display: flex;

  padding-top: 8px;

  justify-content: center;
  align-items: center;
  gap: 8px;
}

.line {
  height: 2px;
  width: calc((100% - 273px) / 2);

  background-color: rgba(113, 121, 143, 0.1);
}

.buttonCopy,
.buttonPaste,
.buttonEdit,
.buttonDelete,
.buttonOpen {
  display: flex;

  height: 24px;
  width: 24px;

  justify-content: center;
  align-items: center;
}

.dropdown {
  margin-top: 8px;
  margin-left: 55px;
  padding: 4px 16px 8px;

  background: #eef0ff;
  border-radius: 12px;
}

.sections {
  display: flex;

  flex-direction: column;
}

.editForm {
  display: flex;

  align-items: center;
  gap: 8px;
}

.editForm__left {
  display: flex;

  flex: 1;
  padding: 6px;

  align-items: center;
  gap: 8px;

  background-color: #eef0ff;
  border-radius: 12px;
}

.newImage {
  display: flex;
  position: relative;

  height: 64px;
  width: 64px;

  justify-content: center;
  align-items: center;

  background: #ffffff;
  border: 1px dashed #8c9aaf;
  border-radius: 12px;

  overflow: hidden;
}

.newImage img {
  height: 100%;
  width: 100%;
}

.buttons {
  display: flex;

  gap: 8px;
}
