.container {
  display: flex;
  position: relative;

  height: 56px;

  user-select: none;
  cursor: pointer;
}

.header {
  display: flex;

  height: 100%;
  width: 100%;

  padding: 16px 15px;

  justify-content: space-between;
  align-items: center;

  border: 2px solid rgba(140, 154, 175, 0.25);
  border-radius: 14px;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #20233a;
}

.header_placeholder {
  color: #808080;
}

.options {
  display: none;

  position: absolute;
  z-index: 1;

  width: 100%;

  top: 56px;

  flex-direction: column;

  background-color: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.11);
  border-radius: 12px;

  overflow: hidden;
}

.options_active {
  display: flex;
}

.option {
  display: flex;

  height: 56px;
  width: 100%;

  padding: 12px 8px;

  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.option:hover {
  background-color: #f5f5f5;
}

.option:disabled {
  opacity: 50%;
}

.option span {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #20233a;
}
