.container {
  display: flex;

  width: 404px;

  flex-direction: column;
  gap: 26px;
}

.header {
  display: flex;

  justify-content: space-between;
  align-items: center;
}

.title {
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;

  color: #20233a;
}

.buttonClose {
  height: 24px;
  width: 24px;
}

.form {
  display: flex;

  flex-direction: column;
  gap: 24px;
}

.fields {
  display: flex;

  flex-direction: column;
  gap: 8px;
}

.roleAndStatus {
  display: flex;

  gap: 8px;
}

.multiSelect {
  width: 198px;
  height: 56px;

  border: 2px solid rgba(140, 154, 175, 0.25);
  border-radius: 14px;
}

.buttons {
  display: flex;

  gap: 8px;
}
