.table__row {
  display: flex;

  height: 60px;

  transition: all 0.3s ease;
}

.table__row:hover {
  background-color: #eef0ff;
}

.table__row:hover h6 {
  color: #5770f3;
}

.table__row_even {
  background-color: #fafafa;
}

.table__cell {
  display: flex;

  height: 60px;
  padding: 10px 15px;

  align-items: center;
}

.table__cell_id {
  width: 40%;
  border-right: 2px solid #e9ecf0;
}

.table__cell_type {
  width: 18%;
  border-right: 2px solid #e9ecf0;
}

.table__cell_createdAt {
  width: 24%;
  border-right: 2px solid #e9ecf0;
}

.table__cell_result {
  width: 17%;
}

.table__cell_result__succeed {
  color: #66C84D;
  font-size: 16px;
  font-weight: 500;
}

.table__cell_result__error {
  color: #F44;
  font-size: 16px;
  font-weight: 500;
}
