.skeleton {
  position: relative;

  height: 100%;
  width: 100%;

  background: var(--color-skeleton);
  /* box-shadow: var(--shadow-skeleton); */
  overflow: hidden;
}

.skeleton::before {
  position: absolute;
  top: 0;
  left: -150px;

  display: block;
  content: '';

  height: 100%;
  width: 80%;

  background: linear-gradient(
    to right,
    transparent 0%,
    var(--color-skeleton-animation) 50%,
    transparent 100%
  );
  animation: loading 1s cubic-bezier(0.5, 0, 0.2, 1) infinite;
}

@keyframes loading {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}
