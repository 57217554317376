.container {
  display: flex;
  position: relative;

  width: 335px;
  height: 160px;

  padding: 20px;

  gap: 8px;

  border-radius: 30px;

  overflow: hidden;
  cursor: pointer;
}

.header {
  display: flex;

  height: 24px;
  width: 100%;
  flex-shrink: 0;

  align-items: center;
}

.theme {
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  color: #5a667b;
}

.title {
  display: -webkit-box;

  margin-bottom: auto;

  max-height: 40px;
  max-width: 160px;

  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #20233a;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */

  word-wrap: break-word;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}

.buttonEdit {
  display: flex;
  z-index: 1;

  width: 156px;
  padding: 12px 16px 12px 12px;

  justify-content: center;
  align-items: center;
  gap: 6px;

  background: rgba(22, 32, 48, 0.06);
  border-radius: 33px;

  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  color: #20233a;
}
