.container {
  display: flex;

  width: 100%;
  height: 88px;

  gap: 24px;

  background-color: #f5f5f5 !important;
  border: 2px solid #f5f5f5 !important;
  border-radius: 20px;

  transition: all 0.3s ease;
  user-select: none;
}

.container_selected {
  background-color: #eef0ff !important;
  border-color: #5770f3 !important;
  border-radius: 20px;
}

.image {
  display: flex;

  height: 84px;
  width: 84px;

  border-radius: 20px;

  overflow: hidden;
}

.image img {
  height: 100%;
  width: 100%;
}

.text {
  display: flex;

  max-width: calc(100% - 112px);

  padding-top: 12.5px;
  padding-left: 16px;

  flex-direction: column;
  align-items: flex-start;
  gap: 4.5px;
}

.level {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #5a667b;
}

.title {
  max-width: 100%;
  flex: 1;

  font-weight: 700;
  font-size: 21px;
  line-height: 24px;
  color: #20233a;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
