.container {
  display: flex;

  width: 404px;

  flex-direction: column;
  gap: 26px;
}

.header {
  display: flex;

  justify-content: space-between;
  align-items: center;
}

.title {
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;

  color: #20233a;
}

.buttonClose {
  height: 24px;
  width: 24px;
}

.form {
  display: flex;

  flex-direction: column;
  gap: 24px;
}

.buttons {
  display: flex;

  gap: 8px;
}
