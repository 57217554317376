.open-empty-element {
  background: #5770f3;
}

.not-open-empty-element {
  border: 2px solid #5770f3;
  border-radius: 12px;
  background: white;
}

.is-selected-element {
  background: #5770f3;
}

.not-is-selected-element {
  background: #eef0ff;
}

.select-window {
  position: absolute;
  top: 75.5px;
  left: 0;
  z-index: 3;

  padding-bottom: 12px;

  background: #ffffff;
  box-shadow: 0 10px 44px rgba(0, 0, 0, 0.11);
  border-radius: 12px;

  user-select: none;
}

.step {
  display: flex;

  /* height: 57px; */
  padding: 16px;

  align-items: center;

  font-size: 15px;
  line-height: 25px;
  color: #000000;

  white-space: nowrap;
}

.step_selectable {
  cursor: pointer;
}

.step_selectable:hover {
  background: #eef0ff;
}

.step_test {
  display: flex;

  /* height: 48px; */
  padding: 12px 32px 12px 48px;

  font-size: 15px;
  line-height: 20px;
}
