.container {
  display: flex;

  width: 100%;
  height: 54px;

  padding-right: 9px;

  align-items: center;
  gap: 16px;

  border-radius: 27px;

  user-select: none;
}

.container_selected {
  background-color: #f5f5f5 !important;
}

.avatar {
  height: 54px;
  width: 54px;

  border-radius: 100%;
  overflow: hidden;
}

.avatar img {
  height: 100%;
  width: 100%;
}

.title {
  flex: 1;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #20233a;

  text-align: start;
}

.buttonDelete {
  display: flex;

  height: 24px;
  width: 24px;

  justify-content: center;
  align-items: center;
}
