.container {
  display: flex;

  width: 404px;

  flex-direction: column;
  gap: 24px;
}

.header {
  display: flex;

  justify-content: space-between;
  align-items: center;
}

.title {
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;

  color: #20233a;
}

.buttonClose {
  height: 24px;
  width: 24px;
}

.upload {
  display: flex;

  align-items: center;
  gap: 10px;
}

.upload_active {
  align-items: flex-end;
}

.upload__text {
  display: flex;

  flex-direction: column;
  gap: 4px;
}

.upload__text h4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #20233a;
}

.upload__text p {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #71798f;
}

.upload__info {
  display: flex;

  padding-bottom: 12px;

  flex-direction: column;
  gap: 4px;
}

.upload__info p {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #71798f;
}

.upload__info span {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #8c9aaf;
}

.buttons {
  display: flex;

  gap: 8px;
}
