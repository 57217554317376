.container {
  display: flex;

  flex-direction: column;
}

.url {
  display: flex;

  flex-direction: column;
}

.label {
  margin-bottom: 8px;

  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #71798f;

  text-transform: uppercase;
}

.video {
  display: flex;

  gap: 8px;
}

.buttonRemove {
  display: flex;

  height: 48px;
  width: 56px;

  justify-content: center;
  align-items: center;

  border-radius: 12px;
  background-color: #eef0ff;
}
