.container {
  display: flex;

  width: 1115px;

  flex-direction: column;
}

.header {
  display: flex;

  margin-bottom: 16px;

  justify-content: space-between;
  align-items: center;
}

.title {
  font-weight: 700;
  font-size: 21px;
  line-height: 24px;

  color: #20233a;
}

.buttonClose {
  height: 24px;
  width: 24px;
}

.table {
  display: flex;

  margin-bottom: 16px;
  height: 413px;
  /* padding-bottom: 16px; */

  flex-direction: column;

  overflow-y: auto;
}

.emptySearchMessage {
  display: flex;

  height: 176px;
  width: 100%;

  justify-content: center;
  align-items: center;
}

.noResultsMessage {
  display: flex;

  height: 262px;
  width: 100%;

  justify-content: center;
  align-items: center;

  flex-direction: column;
  gap: 32px;
}

.emptySearchMessage span,
.noResultsMessage span {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #71798f;
}

.table__headers {
  display: flex;

  height: 60px;

  border-top: 2px solid #e9ecf0;
  border-bottom: 2px solid #e9ecf0;
}

.table__header {
  display: flex;

  padding: 18px 15px;

  justify-content: space-between;
  align-items: center;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #71798f;
}

.table__header_kr {
  width: 15%;
  border-right: 2px solid #e9ecf0;
}

.table__header_ru {
  width: 15%;
  border-right: 2px solid #e9ecf0;
}

.table__header_references {
  width: 20%;
  border-right: 2px solid #e9ecf0;
}

.table__header_examples {
  width: calc(100% - 15% - 15% - 20% - 160px);
  border-right: 2px solid #e9ecf0;
}

.table__header_add {
  width: 160px;
}

.table__rows {
  display: flex;

  flex-direction: column;

  background-color: #ffffff;
  border-bottom: 2px solid #e9ecf0;
}

.table__row {
  display: flex;

  height: auto;

  transition: all 0.3s ease;
}

.table__row:hover {
  background-color: #eef0ff;
}

.table__row_even {
  background-color: #fafafa;
}

.table__cell {
  display: flex;

  padding: 16px 15px;

  align-items: center;
}

.table__cell_name {
  width: 29%;
  border-right: 2px solid #e9ecf0;
}

.table__cell_name span {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #5770f3;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table__cell_original {
  width: 15%;
  border-right: 2px solid #e9ecf0;
}

.table__cell_original span {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #20233a;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table__cell_translation {
  width: 28.3%;
  border-right: 2px solid #e9ecf0;
}

.table__cell_translation {
  width: 15%;
  border-right: 2px solid #e9ecf0;
}

.table__cell_references {
  width: 20%;
  border-right: 2px solid #e9ecf0;
}

.table__cell_translation span {
  font-size: 16px;
  line-height: 24px;
  color: #20233a;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table__cell_examples {
  width: calc(100% - 15% - 15% - 20% - 160px);
  border-right: 2px solid #e9ecf0;
}

.table__cell_buttonAdd {
  width: 160px;

  padding: 8px 0 8px 15px;
}
