.sidebar {
  display: flex;

  width: 210px;

  flex-direction: column;
  align-items: flex-start;
}

.sidebar__back {
  display: flex;

  height: 28px;
  margin-bottom: 35px;

  align-items: center;
  gap: 4px;

  user-select: none;
}

.sidebar__back p {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #71798f;
}

.sidebar__info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.imageWrapper {
  position: relative;
}

.imageDeleteButton {
  position: absolute;
  right: -8px;
  top: -8px;

  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: #71798F;

  color: white;
}

.image {
  display: flex;

  border-radius: 12px;

  width: 210px;
  height: 168px;

  justify-content: center;
  align-items: center;

  overflow: hidden;
  transition: all 0.3s ease;
}

.image_placeholder {
  height: 100px;
  width: 100px;

  background-color: #eef0ff;
  border: 2px dashed #5770f3;
  border-radius: 12px;
}

.image img {
  height: 100%;
  width: 100%;
}

.sidebar__text {
  text-align: center;
}

.sidebar__text p {
  margin-bottom: 4px;

  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #20233a;
}

.sidebar__text span {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #71798f;
}

.upload-button {
  color: #5770F3;
}
