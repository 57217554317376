.root {
  position: relative;
}

.itemsTitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  color: #71798F;
  padding: 16px 16px 0;
}

.divider {
  height: 1px;
  background: #E9ECF0;
}

.itemButton {
  display: flex;
  gap: 10px;
  align-items: center;

  width: 100%;
  padding: 16px;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #20233A;
}

.itemButton:hover {
  background: #f3f3f3;
}

.menuButton {
  display: flex;
  gap: 8px;
  align-items: center;

  border-radius: 25px;
  padding: 15px 25px;
  background: #5770F3 !important;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  color: #FFFFFF;
}

.container {
  position: absolute;
  border-radius: 12px;
  background: #FFF;
  top: 62px;
  right: 0;
  /* тень меню */
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.11);
}

