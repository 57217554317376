.content {
  display: flex;

  width: 100%;
  flex: 1;

  flex-direction: column;
}

.header {
  display: flex;

  margin-bottom: 32px;

  flex-direction: column;
}

.header__title {
  display: flex;
  justify-content: space-between;

  margin-bottom: 36px;

  font-weight: 700;
  font-size: 36px;
  line-height: 50px;

  color: #20233a;
}

.header__level {
  margin-bottom: 8px;

  font-size: 14px;
  line-height: 16px;
  color: #71798f;
}

.header__name {
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #20233a;
}

.modules__list {
  display: flex;

  flex-direction: column;
  gap: 16px;
}

.lessons {
  display: flex;

  flex-direction: column;
  gap: 8px;
}
