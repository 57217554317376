.form {
  display: flex;

  flex-direction: column;
}

.buttons {
  display: flex;

  align-self: flex-end;
  gap: 8px;
}
