.title {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
}

.field__title {
    font-size: 13px;
    font-weight: 500;
    color: #5A667B;
}

.field__value {
    color: #20233A;
    font-size: 16px;
    font-weight: 400;
}

.status_success {
    color: #66C84D;
    font-size: 16px;
    font-weight: 500;
}

.status_error {
    color: #F44;
    font-size: 16px;
    font-weight: 500;
}

.email {
    color: #5770F3;
    font-size: 16px;
    font-weight: 400;
}