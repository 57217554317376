.container {
  display: flex;

  justify-content: space-between;
  align-items: center;

  user-select: none;
}

.link {
  display: flex;

  height: 28px;

  align-items: center;
  gap: 4px;
}

.link__text {
  font-size: 14px;
  line-height: 16px;
  color: #5770f3;
}

.controls {
  display: flex;

  gap: 32px;
}

.buttonEdit,
.buttonDelete {
  display: flex;

  height: 24px;
  width: 24px;

  justify-content: center;
  align-items: center;
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #20233a;
}

.formContainer {
  display: flex;

  flex: 1;

  flex-direction: column;
}

.label {
  margin-bottom: 8px;

  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #71798f;

  text-transform: uppercase;
}

.editForm {
  display: flex;

  flex: 1;

  gap: 8px;
}

.buttons {
  display: flex;

  gap: 8px;
}
