.container {
  display: flex;

  width: calc(100% - 264px);

  flex-direction: column;
}

.header {
  display: flex;

  margin-bottom: 32px;

  height: 50px;

  justify-content: space-between;
  align-items: center;
}

.header__title {
  font-weight: 700;
  font-size: 29px;
  line-height: 35px;
  color: #20233a;
}

.modules {
  margin-bottom: 16px;
}

.addModule {
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 8px;
}

.line {
  height: 2px;
  width: calc((100% - 276px) / 2);

  background-color: rgba(113, 121, 143, 0.1);
}
