* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  scroll-behavior: smooth;
}

body {
  color: #2f324f;
  background: white;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
  font-size: 16px;
}

code {
  font-family: Consolas, monospace;
}

::-webkit-scrollbar {
  height: 11px;
  width: 9px;
}

::-webkit-scrollbar-track {
  background: #e9ecf0;
  border-radius: 18px;
}

::-webkit-scrollbar-thumb {
  background-color: #8c9aaf;
  border-radius: 18px;
}

.h3 {
  font-family: Roboto, sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.h4 {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.h5 {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.caption-small {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
