.container {
  display: flex;
  position: relative;

  flex-direction: column;

  user-select: none;
}

.header {
  display: flex;
  position: relative;

  height: 64px;

  padding: 20px 24px 20px 19px;

  align-items: center;
  gap: 16px;

  cursor: pointer;
}

.title {
  flex: 1;

  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #20233a;
}

.buttonOpen {
  display: flex;

  height: 24px;
  width: 24px;

  justify-content: center;
  align-items: center;
}

.lessons {
  display: flex;

  flex-direction: column;
  gap: 8px;
}
