.container {
  display: flex;
  position: relative;

  flex-direction: column;

  background-color: #ffffff;
}

.header {
  display: flex;

  min-height: 76px;

  margin-bottom: 32px;

  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.statusesSelect {
  height: 56px;
  min-width: 150px;

  border: 2px solid rgba(140, 154, 175, 0.25);
  border-radius: 14px;

  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #20233a;
}

.catalogCard {
  display: flex;
  position: relative;

  width: 335px;
  height: 160px;

  padding: 20px;

  flex-direction: column;

  background-color: rgba(102, 200, 77, 0.15);
  border-radius: 23px;

  overflow: hidden;
  cursor: pointer;
}

.catalogCard__header {
  display: flex;

  height: 24px;
  width: 100%;

  margin-bottom: 4px;

  justify-content: space-between;
  align-items: center;
}

.catalogCard__level {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;

  color: #5a667b;
}

.catalogCard__content {
  display: flex;

  height: 100%;
  justify-content: space-between;
}

.catalogCard__titleAndButton {
  z-index: 1;

  width: 179px;

  display: flex;

  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.catalogCard__title {
  max-height: 40px;
  max-width: 160px;

  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #20233a;

  word-wrap: break-word;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}

.buttonGo {
  display: flex;

  padding: 12px 16px;

  justify-content: center;
  align-items: center;

  background: rgba(22, 32, 48, 0.06);
  border-radius: 33px;

  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #20233a;
}

.catalogCard__image {
  position: absolute;

  right: 0;
  bottom: 0;

  height: 140px;
  width: 180px;
}

.catalogCard__image img {
  height: 100%;
  width: 100%;
}
