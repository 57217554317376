.container {
  display: flex;

  align-items: center;
  gap: 20px;
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #71798f;
}

.list {
  display: flex;

  flex-wrap: wrap;
  gap: 16px;
}

.item {
  display: flex;

  height: 40px;
  width: 40px;

  justify-content: center;
  align-items: center;
}

.item_active {
  border: 2px solid #5770f3;
  border-radius: 100%;
}

.button {
  height: 32px;
  width: 32px;

  border-radius: 100%;
}
