.hint {
  display: flex;

  margin-bottom: 40px;

  flex-direction: column;
  gap: 8px;
}

.label {
  display: flex;

  align-items: flex-end;
  gap: 4px;

  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #71798f;

  text-transform: uppercase;
}

.hint__description {
  font-size: 14px;
  line-height: 16px;

  color: #71798f;
}
