.container {
  display: flex;

  gap: 8px;
}

.image {
  display: flex;
  position: relative;

  height: 64px;
  width: 64px;
  flex-shrink: 0;

  justify-content: center;
  align-items: center;

  background-color: #eff1f4;
  border: 1px dashed #8c9aaf;
  border-radius: 12px;

  overflow: hidden;
}

.image img {
  height: 100%;
  width: 100%;
}
