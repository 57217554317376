.container {
  display: flex;

  width: 584px;

  flex-direction: column;
  /* gap: 26px; */
}

.header {
  display: flex;

  margin-bottom: 16px;

  height: 32px;

  justify-content: space-between;
  align-items: center;
}

.header__title {
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;

  color: #20233a;
}

.buttonClose {
  height: 24px;
  width: 24px;
}

.preview {
  display: flex;

  margin-bottom: 24px;
  height: 88px;
  padding: 0 24px;

  align-items: center;

  border-radius: 20px;
  background: #eef0ff;
}

.preview__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #20233a;
}

.label {
  display: flex;

  align-items: center;
  gap: 8px;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #71798f;
}

.form {
  display: flex;

  flex-direction: column;
}

.courses {
  display: flex;

  margin-bottom: 24px;

  max-height: 280px;

  flex-direction: column;
  gap: 8px;

  overflow-y: auto;
}

.buttons {
  display: flex;

  gap: 8px;
}
