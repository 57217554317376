.container {
  display: flex;
  position: relative;

  flex-direction: column;
}

.header {
  display: flex;
  margin-bottom: 24px;

  width: 100%;

  justify-content: space-between;
  gap: 10px;
}

.table {
  display: flex;

  width: 100%;

  flex-direction: column;
}

.table__filters {
  margin-bottom: 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 12px;
}

.selectRoles,
.selectStatus {
  height: 54px;
  width: 200px;

  border: 2px solid rgba(140, 154, 175, 0.25);
  border-radius: 14px;
}

.buttonResetFilters {
  margin-right: auto;

  width: 169px;
  height: 46px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  border-radius: 16px;

  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #8c9aaf;
}

.table__headers {
  display: flex;

  height: 60px;

  border-top: 2px solid #e9ecf0;
  border-bottom: 2px solid #e9ecf0;
}

.table__header {
  display: flex;

  padding: 18px 16px;

  justify-content: space-between;
  align-items: center;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #71798f;
}

.table__header_name {
  width: 26%;
  border-right: 2px solid #e9ecf0;
}
.table__header_email {
  width: 26%;
  border-right: 2px solid #e9ecf0;
}
.table__header_role {
  width: 16%;
  border-right: 2px solid #e9ecf0;
}
.table__header_status {
  width: 16%;
  border-right: 2px solid #e9ecf0;
}
.table__header_actions {
  width: 16%;
}

.table__rows {
  display: flex;

  margin-bottom: 26px;

  flex-direction: column;

  background-color: #ffffff;
  border-bottom: 2px solid #e9ecf0;
}

.table__row {
  display: flex;

  height: 60px;

  transition: all 0.3s ease;
}

.table__row:hover {
  background-color: #eef0ff;
}

.table__row_even {
  background-color: #fafafa;
}

.table__cell {
  display: flex;

  height: 60px;

  padding: 20px 15px;

  align-items: center;
}

.table__cell_name {
  width: 26%;
  border-right: 2px solid #e9ecf0;
}
.table__cell_name span {
  font-size: 16px;
  line-height: 19px;
  color: #5770f3;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table__cell_email {
  width: 26%;
  border-right: 2px solid #e9ecf0;
}
.table__cell_email span {
  font-size: 16px;
  line-height: 19px;
  color: #20233a;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table__cell_role {
  width: 16%;
  border-right: 2px solid #e9ecf0;
}
.table__cell_role span {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #71798f;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table__cell_status {
  position: relative;

  width: 16%;
  padding: 10px 15px;
  border-right: 2px solid #e9ecf0;
}

.table__cell_status span {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #71798f;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table__cell_actions {
  width: 16%;

  gap: 32px;
}

.table__pagination {
  flex: 1;
  width: 100%;
}
