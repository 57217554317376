.info {
  display: flex;

  height: 100%;
  width: 300px;

  flex-direction: column;
}

.image {
  position: relative;

  margin-bottom: 24px;

  height: 100px;
  width: 100px;

  border-radius: 6px;
  background: #eff1f4;

  user-select: none;
  overflow: hidden;
  cursor: pointer;
}

.image img {
  height: 100%;
  width: 100%;
}

.uploadIcon {
  position: absolute;
  display: flex;

  right: 0;
  bottom: 0;

  height: 40px;
  width: 40px;

  justify-content: center;
  align-items: center;

  border-radius: 6px;
  background: rgba(0, 0, 0, 0.2);

  color: #ffffff;
}

.uploadInput {
  display: none;
}

.status {
  margin-bottom: 8px;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.name {
  margin-bottom: 8px;

  font-weight: 700;
  font-size: 22px;
  line-height: 26px;

  color: #20233a;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.role,
.email {
  margin-bottom: 24px;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #20233a;
}

.email {
  font-size: 16px;
  line-height: 19px;

  color: #5770f3;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.editButton {
  display: flex;

  margin-bottom: 24px;

  align-items: center;
  gap: 10px;
}

.editText {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #71798f;
}

.sidebar__options {
  display: flex;

  margin-bottom: auto;

  flex-direction: column;
  gap: 5px;
}

.sidebar__option {
  display: flex;

  height: 44px;
  width: 202px;

  padding: 14px 0 11px 21px;

  border-radius: 34px;

  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #20233a;
  text-align: start;

  transition: all 0.3s ease;
}

.sidebar__option_active {
  background-color: #eef0ff;
}
