.speakers__header {
  display: flex;

  margin-bottom: 16px;

  justify-content: space-between;
  align-items: center;
}

.speakers__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #71798f;
}

.speakers {
  display: flex;

  margin-bottom: 30px;

  flex-direction: column;
}

.speakers__list {
  display: flex;

  flex-wrap: wrap;
  gap: 16px;
}

.buttons {
  display: flex;

  justify-content: flex-end;
  gap: 8px;

  user-select: none;
}

.errorMessage {
  display: block;

  margin-top: 6px;
  margin-left: 16px;

  font-size: 12px;
  line-height: 14px;

  color: #ff4444;
}
