.container {
  display: flex;

  width: 404px;

  flex-direction: column;
}

.header {
  display: flex;

  margin-bottom: 24px;
  padding: 4px 8px 4px 0;

  justify-content: space-between;
  align-items: flex-start;
}

.title {
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;

  color: #20233a;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.buttonClose {
  height: 24px;
  width: 24px;
}

.text {
  margin-bottom: 12px;

  font-size: 15px;
  line-height: 20px;
  color: #71798f;
}

.form {
  display: flex;

  flex-direction: column;
}

.fields {
  display: flex;

  margin-bottom: 24px;

  flex-direction: column;
  gap: 8px;
}

.roleAndStatus {
  display: flex;

  gap: 8px;
}

.multiSelect {
  width: 198px;
  height: 56px;

  border: 2px solid rgba(140, 154, 175, 0.25);
  border-radius: 14px;
}

.buttons {
  display: flex;

  gap: 8px;
}
