.buttonReset {
  display: flex;

  width: 169px;
  height: 46px;

  justify-content: center;
  align-items: center;
  gap: 4px;

  border-radius: 16px;

  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #8c9aaf;
}

/* headers */
.table__headers {
  display: flex;

  height: 60px;

  border-top: 2px solid #e9ecf0;
  border-bottom: 2px solid #e9ecf0;
}

.table__header {
  display: flex;

  padding: 18px 16px;

  justify-content: space-between;
  align-items: center;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #71798f;
}

.table__header_preview {
  width: 35%;
  padding-left: 71px;
  border-right: 2px solid #e9ecf0;
}
.table__header_startDate {
  width: 13%;
  border-right: 2px solid #e9ecf0;
}
.table__header_endDate {
  width: 13%;
  border-right: 2px solid #e9ecf0;
}
.table__header_for {
  width: 13%;
  border-right: 2px solid #e9ecf0;
}
.table__header_status {
  width: 13%;
  border-right: 2px solid #e9ecf0;
}
.table__header_actions {
  width: 13%;
}

/* rows */
.table__rows {
  display: flex;

  margin-bottom: 26px;

  flex-direction: column;

  background-color: #ffffff;
  border-bottom: 2px solid #e9ecf0;
}
