.container {
  display: flex;

  align-items: center;
}

.dragHandle {
  display: flex;

  margin-right: 8px;

  height: 24px;
  width: 24px;

  justify-content: center;
  align-items: center;

  cursor: grab;
}

.label {
  margin-left: 32px;
  margin-bottom: 8px;

  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #71798f;

  text-transform: uppercase;
}
