.container {
  display: flex;
  position: relative;

  height: 100%;

  flex-direction: column;

  background-color: #ffffff;
}

.buttonBack {
  display: flex;

  margin-top: 44px;
  margin-bottom: 17px;

  align-self: flex-start;

  align-items: center;
  gap: 16px;

  font-size: 16px;
  line-height: 19px;
  color: #20233a;

  user-select: none;
}

.header {
  display: flex;

  margin-bottom: 30px;
  margin-left: 40px;

  height: 74px;

  align-items: center;
}

.title {
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  color: #20233a;
}

.form {
  display: flex;

  margin-left: 40px;

  height: 100%;

  flex-direction: column;
  justify-content: space-between;
}
