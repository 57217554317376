.container {
  display: flex;

  gap: 54px;
}

.sidebar {
  display: flex;

  width: 210px;

  flex-direction: column;
  align-items: flex-start;
}

.sidebar__back {
  display: flex;

  height: 28px;

  align-items: center;
  gap: 4px;

  user-select: none;
}

.sidebar__back p {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #71798f;
}
