.container {
  display: flex;

  height: 54px;

  padding-right: 20px;

  align-items: center;
  gap: 12px;

  background: #eef0ff;
  border-radius: 27px;
}

.avatar {
  height: 54px;
  width: 54px;

  border-radius: 100%;
  overflow: hidden;
}

.avatar img {
  height: 100%;
  width: 100%;
}

.title {
  font-size: 16px;
  line-height: 19px;
  color: #20233a;
}

.buttonDelete {
  display: flex;

  height: 24px;
  width: 24px;

  justify-content: center;
  align-items: center;
}
