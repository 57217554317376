.container {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 15;

  justify-content: center;
  align-items: center;
}

.backdrop {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: #162030;
  opacity: 0.15;

  cursor: pointer;
}

.body {
  display: flex;
  position: fixed;

  max-width: 90%;
  max-height: 90%;

  padding: 32px 28px;

  background: #ffffff;
  border-radius: 16px;
}
