.container {
  display: flex;

  align-items: center;
}

.dragHandle {
  display: flex;

  margin-right: 8px;

  height: 24px;
  height: 24px;

  justify-content: center;
  align-items: center;

  cursor: grab;
}
