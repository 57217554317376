.container {
  display: flex;

  width: calc(100% - 264px);

  flex-direction: column;
}

.header {
  display: flex;

  margin-bottom: 32px;

  height: 50px;

  justify-content: space-between;
  align-items: center;
}

.header__title {
  font-weight: 700;
  font-size: 29px;
  line-height: 35px;
  color: #20233a;
}

.dropdown {
  margin-bottom: 16px;
}

.lessons {
  display: flex;

  flex-direction: column;
  gap: 8px;
}
