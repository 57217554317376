/*
* react-calendar-heatmap styles
*
* All of the styles in this file are optional and configurable!
 * The github and gitlab color scales are provided for reference.
 */

.react-calendar-heatmap {
  width: 100%;
}

.react-calendar-heatmap text {
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  fill: #71798f;

  user-select: none;
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
  font-size: 5px;
}

.react-calendar-heatmap rect:hover {
  stroke: #555;
  strokewidth: 1px;
}

/*
 * Default color scale
 */

.react-calendar-heatmap .color-empty {
  fill: #e9ecf0;
}

.react-calendar-heatmap .color-filled {
  fill: #66c84d;
}

/*
 * Github color scale
 */

.react-calendar-heatmap .color-github-0 {
  fill: #eeeeee;
}

.react-calendar-heatmap .color-github-1 {
  fill: #d6e685;
}

.react-calendar-heatmap .color-github-2 {
  fill: #8cc665;
}

.react-calendar-heatmap .color-github-3 {
  fill: #44a340;
}

.react-calendar-heatmap .color-github-4 {
  fill: #1e6823;
}

/*
 * Gitlab color scale
 */

.react-calendar-heatmap .color-gitlab-0 {
  fill: #ededed;
}

.react-calendar-heatmap .color-gitlab-1 {
  fill: #acd5f2;
}

.react-calendar-heatmap .color-gitlab-2 {
  fill: #7fa8d1;
}

.react-calendar-heatmap .color-gitlab-3 {
  fill: #49729b;
}

.react-calendar-heatmap .color-gitlab-4 {
  fill: #254e77;
}

/* Custom styles for weekday labels */
.react-calendar-heatmap .color-scale .scale-labels .scale-label:nth-child(2n) {
  display: block !important; /* Display all weekdays */
}

/* Custom styles for month labels */
.react-calendar-heatmap .react-calendar-heatmap-month-label {
  display: block !important; /* Display all month labels */
}
