.buttonBack {
  display: flex;

  margin-top: 44px;
  margin-bottom: 17px;

  align-self: flex-start;

  align-items: center;
  gap: 16px;

  font-size: 16px;
  line-height: 19px;
  color: #20233a;

  user-select: none;
}

.header {
  display: flex;

  margin-bottom: 30px;
  margin-left: 40px;

  height: 74px;

  align-items: center;
}

.title {
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  color: #20233a;
}

.form {
  display: flex;

  margin-left: 40px;

  height: 100%;

  flex-direction: column;
  justify-content: space-between;
}

.form__top {
  display: flex;
  flex-direction: column;

  margin-bottom: auto;
}

.name {
  margin-bottom: 30px;

  width: 100%;
  flex: 1;
}

.name__hint {
  font-size: 18px;
  line-height: 21px;
  color: #71798f;
}

.form__bottom {
  display: flex;

  justify-content: flex-end;
  align-items: center;
}

.form__bottom span {
  margin-right: 16px;

  font-size: 18px;
  line-height: 21px;
  color: #20233a;
}
