.button {
  display: flex;

  height: 64px;

  padding: 22.5px 24px;

  align-items: center;
  justify-content: center;

  border-radius: 16px;

  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  transition: all 0.3s ease;
  user-select: none;
}

.button:disabled {
  opacity: 0.3;
}

.button_primary {
  background-color: #5770f3 !important;

  color: #ffffff;
}

.button_secondary {
  padding: 20.5px 24px;

  background-color: #ffffff !important;
  border: 2px solid rgba(140, 154, 175, 0.2);

  color: #8c9aaf;
}

.button_dark {
  background-color: #162030 !important;

  color: #ffffff;
}

.button_light {
  background-color: #EEF0FF !important;

  color: #20233A;
}

.button_warning {
  background-color: #fbe8e8 !important;
  color: #ff4444;
}

.button_big {
  flex: 1;
  width: 100%;
}
