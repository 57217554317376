.container {
  display: flex;
  position: relative;

  height: 100%;

  flex-direction: column;

  background-color: #ffffff;
}

.header {
  display: flex;

  height: 74px;

  margin-bottom: 42px;

  align-items: center;
}

.title {
  margin-right: 20px;

  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  color: #20233a;
}

.cards {
  display: flex;

  flex-wrap: wrap;
  gap: 20px;
}

.createCard {
  display: flex;

  width: 358px;
  height: 160px;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  background: rgba(42, 38, 68, 0.1);
  border: 2px dashed #5770f3;
  border-radius: 19px;
}
