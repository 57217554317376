.container {
  display: flex;

  flex-direction: column;

  user-select: none;
}

.image {
  display: flex;

  gap: 8px;
}

.image img {
  max-width: calc(100% - 64px);
}

.buttonRemove {
  display: flex;

  height: 48px;
  width: 56px;

  justify-content: center;
  align-items: center;

  border-radius: 12px;
  background-color: #eef0ff;
}

.upload {
  display: flex;

  flex-direction: column;
}

.label {
  margin-bottom: 8px;

  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #71798f;

  text-transform: uppercase;
}

.upload__label {
  display: block;

  background-color: #eef0ff;
  border: 1px dashed #5770f3;
  border-radius: 12px;

  font-size: 16px;
  line-height: 19px;
  color: #20233a;

  cursor: pointer;
}

.upload__label_error {
  margin-bottom: 6px;

  background-color: rgba(255, 68, 68, 0.1);
}

.input {
  display: none;
}

.upload__errorMessage {
  display: block;
  margin-left: 16px;

  font-size: 12px;
  line-height: 14px;

  color: #ff4444;
}
