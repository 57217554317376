.container {
  display: flex;

  flex-direction: column;
}

.header {
  display: flex;

  margin-bottom: 16px;

  height: 56px;

  justify-content: space-between;
  align-items: center;
}

.title {
  display: flex;

  align-items: center;

  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #20233a;
}

.form {
  display: flex;

  flex-direction: column;
}

.label {
  margin-bottom: 8px;

  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #71798f;

  text-transform: uppercase;
}

.contentParts {
  display: flex;

  margin-bottom: 16px;

  flex-direction: column;
}

.contentParts__label {
  margin-bottom: 16px;
}

.contentParts__list {
  display: flex;

  margin-bottom: 16px;

  flex-direction: column;
  gap: 16px;
}

.contentParts__buttons {
  display: flex;

  align-items: center;
  gap: 8px;
}

.contentParts__error {
  display: block;

  margin-top: 12px;
  margin-left: 16px;

  font-size: 12px;
  line-height: 14px;
  font-weight: 500;

  color: #ff4444;
}

.buttons {
  display: flex;

  align-self: flex-end;
  gap: 8px;
}
