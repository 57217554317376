.container {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;

  justify-content: center;
  align-items: center;
}

.backdrop {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: #162030;
  opacity: 0.15;

  cursor: pointer;
}

.body {
  display: flex;
  position: fixed;

  max-width: 460px;
  padding: 32px 28px;

  flex-direction: column;

  background: #ffffff;
  border-radius: 16px;
}

.header {
  display: flex;

  margin-bottom: 24px;

  justify-content: space-between;
  align-items: flex-start;
}

.title {
  max-width: 372px;

  font-weight: 700;
  font-size: 22px;
  line-height: 26px;

  color: #20233a;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.buttonClose {
  height: 24px;
  width: 24px;
}

.text {
  margin-bottom: 24px;

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #71798f;
}

.buttons {
  display: flex;

  gap: 8px;
}
