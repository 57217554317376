.container {
  display: flex;
  position: relative;

  width: 358px;
  height: 160px;

  padding: 20px;

  flex-direction: column;

  border-radius: 30px;

  overflow: hidden;
  cursor: pointer;
}

.header {
  display: flex;

  min-height: 24px;
  width: 100%;

  margin-bottom: 4px;

  justify-content: space-between;
  align-items: center;
}

.courseLevel {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;

  color: #5a667b;
}

.buttonDelete {
  display: flex;
  z-index: 1;

  height: 24px;
  width: 24px;

  justify-content: center;
  align-items: center;
}

.content {
  display: flex;

  height: 100%;
  justify-content: space-between;
}

.titleAndEdit {
  display: flex;

  width: 160px;

  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.title {
  max-height: 40px;
  width: 190px;

  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #20233a;

  word-wrap: break-word;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}

.buttonEdit {
  display: flex;
  z-index: 1;

  padding: 12px 16px 12px 12px;

  justify-content: center;
  align-items: center;
  gap: 6px;

  background: rgba(22, 32, 48, 0.06);
  border-radius: 33px;

  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #20233a;
}

.image {
  position: absolute;

  right: 0;
  bottom: 0;

  height: 152px;
  width: 152px;
}

.image img {
  height: 100%;
  width: 100%;
}

.statusIcon {
  display: flex;
  position: absolute;

  right: 12px;
  bottom: 12px;

  height: 40px;
  width: 40px;

  justify-content: center;
  align-items: center;

  border-radius: 100%;
}
