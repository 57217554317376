.table__row {
  display: flex;

  height: 72px;

  transition: all 0.3s ease;
}

.table__row:hover {
  background-color: #eef0ff;
}

.table__row_even {
  background-color: #fafafa;
}

.table__cell {
  display: flex;

  padding: 20px 15px;

  align-items: center;
}

.table__cell_index {
  display: flex;

  width: 10%;
  padding: 24px 15px 24px 16px;

  justify-content: flex-start;
  align-items: center;

  border-right: 2px solid #e9ecf0;
}

.table__cell_index span {
  font-size: 16px;
  line-height: 24px;
  color: #20233a;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table__cell_image {
  display: flex;
  gap: 8px;
  width: 179px;
  padding: 0;

  justify-content: center;
  align-items: center;

  border-right: 2px solid #e9ecf0;
}

.image {
  display: flex;
  position: relative;

  height: 60px;
  width: 80px;
  margin-left: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.image_placeholder {
  height: 60px;
  width: 80px;

  background-color: #eef0ff;
  border: 2px dashed #5770f3;
  border-radius: 12px;
}

.image_placeholder:hover {
  background-color: #162030;
  border: none;
  transition: all 0.3s ease;
}

.image_placeholder:hover .iconUpload {
  color: white;
}

.image:hover .image__backdrop {
  opacity: 0.42;
}

.image:hover .image__icon {
  opacity: 1;
}

.image__icon {
  position: absolute;

  left: 5px;
  top: 5px;

  opacity: 0;
  transition: all 0.3s ease;
}

.image__backdrop {
  display: flex;
  position: absolute;

  height: 100%;
  width: 100%;

  justify-content: center;
  align-items: center;

  background-color: #162030;
  border-radius: 12px;

  opacity: 0;
  transition: all 0.3s ease;
}

.image img {
  height: 100%;
  width: 100%;
}

.table__cell_original {
  width: 32%;
  border-right: 2px solid #e9ecf0;
}

.table__cell_original span {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #20233a;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table__cell_translation {
  width: 32%;
  border-right: 2px solid #e9ecf0;
}

.table__cell_translation span {
  font-size: 16px;
  line-height: 24px;
  color: #20233a;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table__cell_actions {
  width: 13%;

  gap: 28px;
}

.buttonAction {
  display: flex;

  height: 24px;
  width: 24px;

  justify-content: center;
  align-items: center;
}

.iconUpload {
  transition: color 0.3s ease; 
  color:#5770f3;
}
