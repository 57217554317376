.open-empty-element {
  background: #5770f3;
}

.not-open-empty-element {
  border: 2px solid #5770f3;
  border-radius: 12px;
  background: white;
}

.is-selected-element {
  background: #5770f3;
}

.not-is-selected-element {
  background: #eef0ff;
}

.select-window {
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 3;

  background: #ffffff;
  box-shadow: 0 10px 44px rgba(0, 0, 0, 0.11);
  border-radius: 12px;
}

.step {
  display: flex;
  align-items: center;
  height: 57px;
  padding-left: 22px;
  padding-right: 32px;
  cursor: pointer;
  white-space: nowrap;
}

.step-not-selectable {
  display: flex;
  align-items: center;
  height: 57px;
  padding-left: 22px;
  padding-right: 32px;
}

.test-step {
  display: flex;
  align-items: center;
  height: 48px;
  padding-left: 52px;
  padding-right: 32px;
  white-space: nowrap;
  cursor: pointer;
}

.step:hover {
  background: #eef0ff;
}

.test-step:hover {
  background: #eef0ff;
}
