.container {
  display: flex;
  position: relative;

  flex-direction: column;
}

.header {
  display: flex;
  margin-bottom: 24px;

  width: 100%;

  justify-content: space-between;
  gap: 10px;
}

.table {
  display: flex;

  width: 100%;

  flex-direction: column;
}

.table__filters {
  margin-bottom: 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 12px;
}

.table__headers {
  display: flex;

  height: max-content;

  border-top: 2px solid #e9ecf0;
  border-bottom: 2px solid #e9ecf0;
}

.table__header {
  display: flex;

  padding: 18px 16px;

  justify-content: space-between;
  align-items: center;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #71798f;
}

.table__header_createdAt {
  width: 20%;
  max-width: 20%;
  border-right: 2px solid #e9ecf0;
}
.table__header_course {
  width: 20%;
  max-width: 20%;
  border-right: 2px solid #e9ecf0;
}
.table__header_userEmail {
  width: 20%;
  max-width: 20%;
  border-right: 2px solid #e9ecf0;
}
.table__header_userName {
  width: 20%;
  max-width: 20%;
  border-right: 2px solid #e9ecf0;
}
.table__header_paidFrom {
  width: 12%;
  max-width: 12%;
  border-right: 2px solid #e9ecf0;
}
.table__header_isActive {
  width: 10%;
  max-width: 10%;
  border-right: 2px solid #e9ecf0;
}

.table__rows {
  display: flex;

  margin-bottom: 26px;

  flex-direction: column;

  background-color: #ffffff;
  border-bottom: 2px solid #e9ecf0;
}

.table__row {
  display: flex;

  height: auto;

  transition: all 0.3s ease;
}

.table__row:hover {
  background-color: #eef0ff;
}

.table__row_even {
  background-color: #fafafa;
}

.table__cell {
  display: flex;

  height: auto;

  overflow: hidden;

  padding: 20px 15px;

  align-items: center;
}

.table__cell_createdAt {
  width: 20%;
  max-width: 20%;
  border-right: 2px solid #e9ecf0;
}
.table__cell_createdAt span {
  font-size: 16px;
  line-height: 19px;
  color: #5770f3;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table__cell_course {
  width: 20%;
  max-width: 20%;
  border-right: 2px solid #e9ecf0;
}
.table__cell_course span {
  font-size: 16px;
  line-height: 19px;
  color: #20233a;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table__cell_userEmail {
  width: 20%;
  max-width: 20%;
  border-right: 2px solid #e9ecf0;
}
.table__cell_userEmail span {
  font-size: 16px;
  line-height: 19px;
  color: #20233a;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table__cell_userName {
  width: 20%;
  max-width: 20%;
  border-right: 2px solid #e9ecf0;
}
.table__cell_userName span {
  font-size: 16px;
  line-height: 19px;
  color: #20233a;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table__cell_paidFrom {
  width: 12%;
  max-width: 12%;
  border-right: 2px solid #e9ecf0;
}
.table__cell_paidFrom span {
  font-size: 16px;
  line-height: 19px;
  color: #20233a;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table__cell_isActive {
  width: 10%;
  max-width: 10%;
  border-right: 2px solid #e9ecf0;
}
.table__cell_isActive span {
  font-size: 16px;
  line-height: 19px;
  color: #20233a;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table__pagination {
  flex: 1;
  width: 100%;
}
