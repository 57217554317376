.form {
  display: flex;

  flex-direction: column;
}

.video {
  display: flex;

  flex-direction: column;
}

.buttonRemove {
  display: flex;

  height: 48px;
  width: 56px;

  justify-content: center;
  align-items: center;

  border-radius: 12px;
  background-color: #eef0ff;
}

.buttons {
  display: flex;

  justify-content: flex-end;
  gap: 8px;
}
