.container {
  display: flex;
  position: relative;

  width: 100%;

  flex-direction: column;
}

.container_small {
  max-width: 404px;
}

.container_big {
  max-width: none;
  flex: 1;
}

.container_disabled {
  opacity: 0.3;
}

.label {
  position: absolute;

  top: 21px;
  left: 16px;

  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  color: #71798f;

  transition: all 0.1s ease-out;
  user-select: none;
}

.label_compressed {
  top: 6px;
  left: 17px;

  font-size: 13px;
  line-height: 18px;
}

.input {
  height: 64px;
  width: 100%;

  color: #20233a;

  transition: all 0.3s ease;
}

.input::placeholder {
  color: #71798f;
}

.input_dark {
  padding: 21px 16px;

  background-color: #eff1f4;
  border-radius: 12px;

  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.input_dark_password {
  padding: 21px 56px 21px 16px;
}

.input_dark:focus,
.input_dark:focus-visible {
  background-color: #eef0ff;

  outline: none;
}

.input_dark.input_withLabel {
  padding: 25px 16px 17px;
}

.input_bordered {
  padding: 20.5px 13px;

  background: #ffffff;
  border: 2px solid rgba(140, 154, 175, 0.25);
  border-radius: 14px;

  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.input_bordered_password {
  padding: 20.5px 50px 20.5px 13px;
}

.input_bordered:focus,
.input_bordered:focus-visible {
  border-color: #5770f3;

  outline: none;
}

.input_bordered.input_withLabel {
  padding: 25px 16px 16px;
}

.input_light {
  padding: 20px 16px;

  background-color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 12px;

  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.input_light_password {
  padding: 20px 50px 20px 16px;
}

.input_light:focus,
.input_light:focus-visible {
  /* background-color: #EEF0FF; */
  border-color: #5770f3;

  outline: none;
}

.input_light.input_withLabel {
  padding: 25px 16px 16px;
}

.input_small {
  max-width: 404px;
}

.input_big {
  max-width: none;
}

.input_error {
  margin-bottom: 6px;

  background-color: rgba(255, 68, 68, 0.1);
}

.eyeIcon {
  position: absolute;

  height: 24px;
  width: 24px;

  top: 20px;
  right: 16px;
}

.buttonGenerate {
  display: flex;
  position: absolute;

  height: 48px;
  width: 64px;

  top: 8px;
  right: 8px;

  justify-content: center;
  align-items: center;

  background: #5770f3 !important;
  border-radius: 12px;
}

.errorMessage {
  display: block;
  margin-left: 16px;

  font-size: 12px;
  line-height: 14px;

  color: #ff4444;
}
