.container {
  display: flex;

  width: 404px;

  flex-direction: column;
}

.header {
  display: flex;

  margin-bottom: 24px;
  padding: 4px 8px 4px 0;

  justify-content: space-between;
  align-items: flex-start;
}

.title {
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;

  color: #20233a;
}

.buttonClose {
  height: 24px;
  width: 24px;
}

.form {
  display: flex;

  flex-direction: column;
}

.fields {
  display: flex;

  margin-bottom: 24px;

  flex-direction: column;
  gap: 8px;
}

.buttons {
  display: flex;

  gap: 8px;
}
