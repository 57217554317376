.container {
  display: flex;

  margin-bottom: 16px;

  flex-direction: column;
  align-items: flex-start;
}

.label {
  margin-bottom: 8px;

  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #71798f;

  text-transform: uppercase;
}
