.container {
  display: flex;

  width: 100%;
  height: 88px;

  padding: 0 24px;

  align-items: center;

  background-color: #f5f5f5 !important;
  border: 2px solid #f5f5f5 !important;
  border-radius: 20px;

  transition: all 0.3s ease;
  user-select: none;
}

.container_selected {
  background-color: #eef0ff !important;
  border-color: #5770f3 !important;
  border-radius: 20px;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #20233a;

  text-align: start;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
