.button {
  display: flex;

  min-width: 141px;

  align-items: center;
  justify-content: center;
  align-self: flex-start;
  gap: 8px;

  border-radius: 62px;

  font-size: 16px;
  line-height: 19px;

  transition: all 0.3s ease;
  user-select: none;
}

.button:disabled {
  opacity: 0.3;
}

.button_small {
  /* height: 48px; */
  padding: 12px 24px 12px 16px;
}

.button_big {
  /* height: 64px; */
  padding: 20px 24px 20px 16px;
}

.button_primary {
  background-color: #5770f3 !important;

  font-weight: 700;
  color: #ffffff;
}

.button_light {
  background-color: #eef0ff !important;

  font-weight: 500;
  color: #20233a;
}

.button_dark {
  background-color: #162030 !important;

  font-weight: 700;
  color: #ffffff;
}
