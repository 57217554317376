.container {
  width: 400px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 24px;
}

.title {
  color: #20233A;

  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.imagesBlock {
  margin-bottom: 24px;
  height: 300px;
  width: 100%;
}

.imagesBlock img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.selectedImage {
  outline: 4px solid #5770F3;
}

.buttons {
  display: flex;
  gap: 8px;
}

.buttons button {
  flex: 1 1 50%;
  width: auto;
}

.generateButton {
  margin-bottom: 24px;
  width: 100%;
  height: 50px;
}

.generateButton:hover {
  background-color: #162030 !important;
}
