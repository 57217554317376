.container {
  display: flex;

  width: calc(100% - 264px);

  flex-direction: column;
}

.header {
  display: flex;

  margin-bottom: 24px;
}

.header__title {
  font-weight: 700;
  font-size: 21px;
  line-height: 24px;
  color: #20233a;
}

.form {
  display: flex;

  flex-direction: column;
}

.words {
  margin-bottom: 24px;

  max-height: 897px;

  overflow-y: auto;
}

.words__list {
  display: flex;

  flex-direction: column;
  gap: 10px;
}

.buttons {
  display: flex;

  align-self: flex-end;
  gap: 8px;
}
