.container {
  display: flex;

  padding: 11px 24px 11px 16px;

  background: #eef0ff;
  border-radius: 12px;

  align-items: center;

  cursor: pointer;
}

.image {
  margin-right: 20px;

  height: 54px;
  width: 54px;
}

.image img {
  height: 100%;
  width: 100%;
}

.text {
  display: flex;

  margin-right: 16px;

  flex: 1;

  flex-direction: column;
  gap: 4px;
}

.heading {
  font-size: 14px;
  line-height: 16px;
  color: #71798f;
}

.name {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #1c2438;
}

.buttonEdit {
  display: flex;

  height: 24px;
  width: 24px;

  justify-content: center;
  align-items: center;
}
