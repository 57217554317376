.container {
  display: flex;

  margin-bottom: 8px;

  flex-direction: column;
}

.header {
  display: flex;
  position: relative;

  height: 64px;

  padding: 20px 24px 20px 16px;

  align-items: center;
  gap: 16px;
}

.header:hover .controls {
  opacity: 1;
}

.header_open {
  border-left: 3px solid #5770f3;
}

.dragHandle {
  display: flex;

  height: 24px;
  width: 24px;

  justify-content: center;
  align-items: center;

  cursor: grab !important;
}

.title {
  flex: 1;

  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #20233a;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.controls {
  display: flex;
  position: absolute;

  padding: 0 32px;
  right: 48px;

  align-items: center;
  gap: 32px;

  background-color: #ffffff;

  opacity: 0;
  transition: all 0.3s ease;
}

.buttonCopy,
.buttonPaste,
.buttonEdit,
.buttonDelete,
.buttonOpen {
  display: flex;

  height: 24px;
  width: 24px;

  justify-content: center;
  align-items: center;
}

.dropdown {
  margin-top: 16px;
  margin-bottom: 8px;
}

.lessons {
  display: flex;

  flex-direction: column;
  gap: 8px;
}
